// @flow

import { graphql } from "gatsby";
import React, { useEffect } from "react";
import Render from "../components/Contentful";
import { PageContent } from "@containers";
import { Meta, StickyButton } from "@components";
import { addLocale, t, useLocale } from "ttag";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
    EVENT_MAP,
    type PageImpressionParams,
    type BannerImpressionParams,
} from "../models/events";
import {
    TrackPageImpression,
    TrackBannerImpression,
} from "../components/Tracker/Tracker";
import { Slider } from "../components/TrustPilot";
const LandingPageTemplate = ({
    data: {
        contentfulWebLandingPage: {
            pageContent,
            pageDescription,
            isThisPageYourHomepage,
        },
        contentfulNavigation: { navigationItems },
        c21Translation: { translations },
        footerItems,
    },
    pageContext: { locale, translationSlugs, country },
}: *) => {
    addLocale(locale, translations);
    useLocale(locale);
    const gtmDispatcher = useGTMDispatch();

    const campaignBanner = pageContent?.filter((item) => {
        if (item.__typename === "ContentfulBanner") {
            return item;
        }
    });

    const bannerImageLink =
        (campaignBanner &&
            campaignBanner[0]?.imageLink?.contentfulInternalLink?.slug) ||
        (campaignBanner && campaignBanner[0]?.imageLink?.externalLink);
    const bannerLinkTitle =
        campaignBanner && campaignBanner[0]?.imageLink.linkTitle;

    useEffect(() => {
        const pageImpressionParams: PageImpressionParams = {
            agency: null,
            agencypage: "false",
            language: locale,
            pagename: "Landing page",
            typetemplate: "landing",
        };
        TrackPageImpression(
            gtmDispatcher,
            EVENT_MAP.PageImpression,
            pageImpressionParams,
        );

        const bannerImpressionParams: BannerImpressionParams = {
            name: "pagebanner",
            language: locale,
            targeturl: bannerImageLink,
            targetname: bannerLinkTitle,
        };

        TrackBannerImpression(
            gtmDispatcher,
            EVENT_MAP.BannerImpression,
            bannerImpressionParams,
        );
    }, []);

    return (
        <PageContent
            locale={locale}
            navigationItems={navigationItems}
            translationSlugs={translationSlugs}
            footerItems={footerItems}
            country={country}
        >
            <Meta
                title={t`title.landing`}
                description={pageDescription}
                translationSlugs={translationSlugs}
                locale={locale}
            />
            <>
                {Render(pageContent, locale)}
                {country === "BE" && isThisPageYourHomepage && (
                    <StickyButton
                        estimationLink={`/${locale}/${t`slug.estimation-tool`}`}
                        estimationLabel={t`agency.header.button.text`}
                    />
                )}
            </>
            {isThisPageYourHomepage && (
                <Slider
                    locale={locale}
                    templateId={
                        process.env.GATSBY_PUBLIC_TRUSTPILOT_CAROUSEL_ID
                    }
                    businessUnitId={
                        process.env.GATSBY_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID
                    }
                    reviewLanguages={locale}
                    dataHeight={"240px"}
                    dataWidth={"100%"}
                    dataStars={"4,5"}
                    dataTheme={"light"}
                />
            )}
        </PageContent>
    );
};

export default LandingPageTemplate;

export const query = graphql`
    query ($locale: String!, $id: String!, $country: String!) {
        contentfulNavigation(
            title: { eq: "Main Navigation" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        footerItems: contentfulNavigation(
            title: { eq: "Footer" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        c21Translation(locale: { eq: $locale }) {
            translations(
                keys: [
                    "common.text.language"
                    "common.filters.*"
                    "api.property.*"
                    "slug.property-overview"
                    "slug.privacy-policy"
                    "common.text.search"
                    "home.category-links"
                    "landing.blog.article"
                    "landing.step-block.step"
                    "form.agency-contact.*"
                    "form.hq-contact.*"
                    "title.landing"
                    "agency.header.button.text"
                    "slug.estimation-tool"
                ]
            )
        }
        contentfulWebLandingPage(
            id: { eq: $id }
            node_locale: { eq: $locale }
        ) {
            pageTitle
            pageDescription
            isThisPageYourHomepage
            pageContent {
                ... on ContentfulCtaGroup {
                    id
                    node_locale
                    ctaTitle
                    ctaDescription {
                        ctaDescription
                    }
                    ctaBackgroundColors
                    ctaContent {
                        ... on ContentfulLink {
                            id
                            linkTitle
                            icon {
                                fixed(width: 100, height: 100) {
                                    width
                                    height
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                }
                            }
                            externalLink
                            contentfulInternalLink {
                                ... on ContentfulWebLandingPage {
                                    slug
                                }
                            }
                        }
                    }
                }

                ... on ContentfulQuickSearchBlock {
                    id
                    node_locale
                    background {
                        size
                        color
                        position
                    }
                    content {
                        contentTitle
                        contentSubtitle
                        squareImage {
                            fluid(maxWidth: 2000, quality: 80) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                            description
                        }
                    }
                    filters
                    quickLinks {
                        type
                        __typename
                        linkItems {
                            ... on ContentfulLink {
                                id
                                linkTitle
                                icon {
                                    fixed(width: 100, height: 100) {
                                        width
                                        height
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                    }
                                }
                                externalLink
                                contentfulInternalLink {
                                    ... on ContentfulWebLandingPage {
                                        slug
                                    }
                                }
                            }
                        }
                    }
                    overlaySelectedBrand {
                        fluid(maxWidth: 2000, quality: 80) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        description
                    }
                }
                ... on ContentfulBanner {
                    id
                    imageLink {
                        ... on ContentfulLink {
                            id
                            linkTitle
                            externalLink
                            contentfulInternalLink {
                                ... on ContentfulWebLandingPage {
                                    slug
                                }
                            }
                        }
                    }
                    desktopImage {
                        fluid(maxWidth: 2344, quality: 80) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        title
                        description
                    }
                    mobileImage {
                        fluid(maxWidth: 2344, quality: 80) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        title
                        description
                    }
                }

                ... on ContentfulQuickLinkBlock {
                    id
                    type
                    title
                    hideStepsTitle
                    background {
                        size
                        color
                        position
                    }
                    linkItems {
                        ... on ContentfulLink {
                            id
                            contentfulInternalLink {
                                ... on ContentfulWebLandingPage {
                                    slug
                                }
                            }
                            externalLink
                            linkTitle
                            linkSubtitle {
                                linkSubtitle
                            }
                            icon {
                                fixed(width: 100, height: 100) {
                                    width
                                    height
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                }
                            }
                            image {
                                fixed(width: 217, height: 185) {
                                    width
                                    height
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                }
                                description
                            }
                        }
                        ... on ContentfulCollection {
                            internalLabel
                            query
                            content {
                                contentTitle
                                squareImage {
                                    fixed(width: 217, height: 185) {
                                        width
                                        height
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                    }
                                    description
                                }
                            }
                        }
                        ... on ContentfulWebLandingPage {
                            id
                            slug
                            pageContent {
                                ... on ContentfulBlog {
                                    id
                                    title
                                    heroImage {
                                        fluid(maxWidth: 568, quality: 80) {
                                            ...GatsbyContentfulFluid_withWebp
                                        }
                                        description
                                    }
                                }
                            }
                        }
                    }
                }
                ... on ContentfulIframe {
                    id
                    hasWidth: width
                    iframeLink {
                        iframeLink
                    }
                }
                ... on ContentfulHeroVideo {
                    id
                    title
                    subtitle {
                        subtitle
                    }
                    videoId
                }
                ... on ContentfulImageInformationBlock {
                    id
                    node_locale
                    type
                    background {
                        size
                        color
                        position
                    }
                    backgroundColor
                    imagePosition
                    textColor
                    maxHeight
                    link {
                        linkTitle
                        externalLink
                        contentfulInternalLink {
                            ... on ContentfulWebLandingPage {
                                slug
                            }
                            ... on ContentfulCollection {
                                query
                            }
                        }
                        fileLink {
                            file {
                                url
                            }
                        }
                    }
                    content {
                        contentTitle
                        contentSubtitle
                        landscapeImage {
                            fluid(maxWidth: 2344, quality: 80) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                            description
                        }
                        squareImage {
                            fluid(maxWidth: 1800, quality: 80) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                            description
                        }
                    }
                }
                ... on ContentfulBlog {
                    id
                    title
                    author {
                        name
                        link
                        image {
                            fixed(width: 100, height: 100) {
                                width
                                height
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                            }
                        }
                    }

                    content {
                        ... on ContentfulRichText {
                            childContentfulRichTextTextRichTextNode {
                                json
                            }
                            internal {
                                type
                            }
                        }
                    }

                    heroImage {
                        fluid(maxWidth: 1172) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        description
                    }
                }
                ... on ContentfulRichText {
                    childContentfulRichTextTextRichTextNode {
                        json
                    }
                    internal {
                        type
                    }
                }
                ... on ContentfulUspBlock {
                    id
                    type
                    uspItems {
                        icon {
                            fixed(width: 100, height: 100) {
                                width
                                height
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                            }
                        }
                        linkTitle
                        linkSubtitle {
                            linkSubtitle
                        }
                    }
                    title
                }
                ... on ContentfulContactHqBlock {
                    id
                    internalLabel
                    internal {
                        type
                    }
                }
                ... on ContentfulContainer {
                    title
                    faq: content {
                        __typename
                        question
                        answer {
                            answer
                        }
                    }
                }
            }
        }
    }
`;
